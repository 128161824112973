<template>
  <NuxtLayout name="base">
    <div class="base">
      <div class="container-dash">
        <slot />
      </div>
    </div>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/colors' as *;
@use '@/assets/scss/breakpoints' as *;
@use '@/assets/scss/sizes' as *;

.base {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #E5E5E5;
  margin-top: 56px;
  min-height: calc(100vh - 56px);

  @include tab {
    margin-top: 64px;
    min-height: calc(100vh - 64px);
  }
}

.container-dash {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: $none $doubleOff;

  @include sm {
    padding: $quadruple;
  }

  @include tab {
    padding: $none $sixfold;
    background: $transparent;
  }

  @include lg {
    padding: $none 64px;
  }

  @include xl {
    padding: $none 104px;
  }
}

.focus {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include md {
    display: flex;
    width: calc((100% / 12) * 10);
  }

  @include xl {
    max-width: calc((100% / 12) * 8);
  }
}

.centered {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  width: 100%;

  @include tab {
    width: calc((100vw / 12) * 8);
    max-width: 720px;
  }

  @include lg {
    width: calc((100vw / 12) * 6);
  }

  @include xl {
    width: calc((100vw / 12) * 5);
  }
}
</style>
